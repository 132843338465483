import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AppBar, Button } from '@material-ui/core'

import NavLinks, { Extrapole } from '../../utils/navLinks'
import Link from '../link'
import { usePageContext } from '../pageContext'
import LanguagePicker from '../languagePicker/languagePicker'

const FloatingNav = () => {
    const { t } = useTranslation()
    const { originalPath } = usePageContext()
    const selected = path => path === originalPath

    const appBarContents = (
        <>
            {NavLinks.map(({ id, path }) => (
                <Button
                    key={id}
                    to={path}
                    // eslint-disable-next-line no-unused-vars
                    component={forwardRef((props, ref) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <Link {...props} />
                    ))}
                    className={`navButton ${selected(path) && 'navButtonSelected'}`}
                >
                    {t(`${id}:title`)}
                </Button>
            ))}
            <Button target="_blank" href={Extrapole.href} className="navButton">
                Extrapole.app
            </Button>
            <LanguagePicker />
        </>
    )

    return (
        <AppBar
            position="fixed"
            classes={{ root: 'appBarRoot', positionFixed: 'appBarPositionFixed' }}
        >
            {appBarContents}
        </AppBar>
    )
}

export default FloatingNav
