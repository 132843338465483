import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip } from '@material-ui/core'
import SocialLinks from '../../utils/socialLinks'

const socialLinks = () => {
    const { t } = useTranslation('socialMedia')

    return (
        <div className="socialLinks">
            {SocialLinks.map(socialLink => (
                <Tooltip
                    interactive
                    placement="top"
                    key={socialLink.id}
                    title={t(`${socialLink.id}`)}
                    aria-label={t(`${socialLink.id}`)}
                    classes={{ tooltip: 'tooltip' }}
                    PopperProps={{ className: `socialLinkTooltip ${socialLink.id}` }}
                >
                    <IconButton
                        aria-label={t(`${socialLink.id}`)}
                        href={socialLink.path}
                        className={`socialLink ${socialLink.id}`}
                        target="_blank"
                        rel="noopener"
                    >
                        {socialLink.icon}
                    </IconButton>
                </Tooltip>
            ))}
        </div>
    )
}

export default socialLinks
