export const Homepage = {
    id: 'homepage',
    path: '/',
}

export const JMSM = {
    id: 'jmsm',
    path: '/je-mise-sur-moi/',
}

export const Services = {
    id: 'services',
    path: '/services/',
}

export const About = {
    id: 'about',
    path: '/a-propos/',
}

export const Contact = {
    id: 'contact',
    path: '/contact/',
}

export const Contributions = {
    id: 'contribution',
    path: '/contribution/',
}

export const Extrapole = {
    id: 'extrapole',
    href: 'https://www.extrapole.app',
}

const NavLinks = [Homepage, JMSM, About, Contact, Contributions]

export default NavLinks
