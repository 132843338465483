import React, { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, IconButton, SwipeableDrawer, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import Link from '../link'
import NavLinks, { Extrapole } from '../../utils/navLinks'
import SocialLinks from '../socialLinks/socialLinks'
import LanguagePicker from '../languagePicker/languagePicker'

import Menu from '../../assets/svg/navIcons/menu.svg'

const Drawer = () => {
    const { t } = useTranslation()
    const [openDrawer, setDrawerOpen] = useState(false)
    // const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

    const toggleDrawer = open => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setDrawerOpen(open)
    }

    const mobileNavContents = (
        <div
            role="presentation"
            className="drawerContents"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <div className="navLinks">
                {NavLinks.map(({ id, path }) => (
                    <Button
                        key={id}
                        to={path}
                        // eslint-disable-next-line no-unused-vars
                        component={forwardRef((props, ref) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <Link {...props} />
                        ))}
                        className={`navButton `}
                    >
                        {t(`${id}:title`)}
                    </Button>
                ))}
                <Button
                    target="_blank"
                    href={Extrapole.href}
                    className="navButton"
                    onClick={toggleDrawer(false)}
                >
                    Extrapole.app
                </Button>
                <LanguagePicker />
            </div>
            <SocialLinks />
        </div>
    )

    return (
        <>
            <Tooltip
                title="Menu"
                aria-label="Menu"
                classes={{ popper: 'navPopper', tooltip: 'navTooltip' }}
            >
                <IconButton
                    aria-label="menu"
                    className="menuIcon"
                    classes={{ root: 'navIconButton' }}
                    color="inherit"
                    onClick={toggleDrawer(true)}
                >
                    <Menu />
                </IconButton>
            </Tooltip>
            <SwipeableDrawer
                keepMounted
                anchor="right"
                open={openDrawer}
                classes={{ paper: 'drawer' }}
                disableDiscovery
                onOpen={toggleDrawer(true)}
                onClose={toggleDrawer(false)}
            >
                {mobileNavContents}
                <IconButton
                    aria-label="close"
                    className="closeButton"
                    onClick={toggleDrawer(false)}
                >
                    <CloseIcon fontSize="large" />
                </IconButton>
            </SwipeableDrawer>
        </>
    )
}

export default Drawer
