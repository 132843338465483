import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core/'

import { currentLanguages } from '../../i18n/config/currentLanguages'
import { usePageContext } from '../pageContext'

function LanguagePicker() {
    const { lang: currentLang, originalPath } = usePageContext()
    const { i18n } = useTranslation()

    const [hiddenLang, setHiddenLang] = useState(currentLang)

    const handleLangChange = lang => {
        i18n.changeLanguage(lang.shorthand)
        setHiddenLang(lang.shorthand)
        window.history.replaceState('', '', `${lang.path}${originalPath}`)
    }

    return (
        <>
            {currentLanguages.map(lang => (
                <Button
                    key={lang.shorthand}
                    onClick={() => handleLangChange(lang)}
                    className={`navButton ${hiddenLang === lang.shorthand && 'hidden'}`}
                >
                    {lang.shorthand.toUpperCase()}
                </Button>
            ))}
        </>
    )
}

export default LanguagePicker
