import React from 'react'
import LinkedIn from '../assets/svg/socialMedia/linkedIn.svg'
import Facebook from '../assets/svg/socialMedia/facebook.svg'
import Instagram from '../assets/svg/socialMedia/instagram.svg'
import Mail from '../assets/svg/socialMedia/mail.svg'

const SocialLinks = [
    {
        id: 'instagram',
        path: 'https://www.instagram.com/extrapole.ca/',
        icon: <Instagram />,
    },
    {
        id: 'facebook',
        path: 'https://www.facebook.com/extrapole.ca',
        icon: <Facebook />,
    },
    {
        id: 'linkedin',
        path: 'https://www.linkedin.com/company/perspective-affaires',
        icon: <LinkedIn />,
    },
    {
        id: 'mail',
        path: 'mailto:info@extrapole.ca',
        icon: <Mail />,
    },
]

export default SocialLinks
