import React from 'react'
import PropTypes from 'prop-types'
import { ParallaxProvider } from 'react-scroll-parallax'

import Header from './header/header'
import Footer from './footer/footer'
import FloatingNav from './floatingNav/floatingNav'

const Layout = ({ children }) => {
    return (
        <ParallaxProvider>
            <Header />
            <FloatingNav />
            <div className="content">
                <main>{children}</main>
            </div>
            <Footer />
        </ParallaxProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
