import React, { forwardRef } from 'react'
import { IconButton } from '@material-ui/core'
import Drawer from '../drawer/drawer'

import Logo from '../../assets/svg/logo.svg'
import { usePageContext } from '../pageContext'
import { Homepage } from '../../utils/navLinks'
import Link from '../link'

const Header = () => {
    const { originalPath } = usePageContext()
    const isHomepage = originalPath === Homepage.path
    return (
        <header>
            <nav>
                <div className="navLHS">
                    <IconButton
                        to={Homepage.path}
                        // eslint-disable-next-line no-unused-vars
                        component={forwardRef((props, ref) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <Link {...props} />
                        ))}
                        aria-label="home"
                        className={`navLogoLink ${isHomepage && 'hidden'}`}
                        color="inherit"
                    >
                        <Logo className={`navLogo ${isHomepage && 'hidden'}`} />
                    </IconButton>
                </div>
                <div className="navRHS">
                    <Drawer />
                </div>
            </nav>
        </header>
    )
}

export default Header
